import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["tokenInput"]

  static values = { url: String }

  generateToken() {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        this.tokenInputTarget.value = data.token
      })
      .catch(error => console.error('Error generating token:', error))
  }
}
