import { Controller } from "stimulus"
import { clipboardCopyHandler } from "../lib/helper_functions"


export default class extends Controller {

  copyToClipboard(e) {
    clipboardCopyHandler(e, 'copied-clipboard')
  }
}
