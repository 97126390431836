import { Controller } from 'stimulus'
import { getMetaValue } from '../lib/helper_functions'

export default class extends Controller {
  static targets = [
    'paymentField', 'reasonComment', 'reason', 'errorMessage', 'closeButton',
    'loaderContainer', 'fullRefund', 'issueButton', 'errorMessageContainer'
  ]

  disabledRefundField(e) {
    let refundField = this.paymentFieldTarget
    refundField.disabled = e.target.checked
    refundField.value = e.target.checked ? '' : refundField.value
  }

  toggleReasonComment() {
    const selectedValue = this.reasonTarget.value
    const reasonComment = this.reasonCommentTarget
  
    const showComment = (placeholder) => {
      reasonComment.classList.add('d-block')
      reasonComment.placeholder = placeholder
    }

    switch (selectedValue) {
      case 'Duplicate':
      case 'Requested by the customer':
        return showComment('Add more details about this refund (max 500 chars)')
      case 'Fraudulent':
        return showComment('Why is this payment Fraudulent? (max 500 chars)')
      case 'Other':
        return showComment('Add reason for this refund (max 500 chars)')
      default:
        reasonComment.classList.remove('d-block')
    }
  }

  validationFieldForRefund(e) {
    const dataSet = e.target.dataset
    const paymentField = this.paymentFieldTarget
    const partialRefundAmount = parseFloat(paymentField.value)
    const remainingFee = parseFloat(dataSet.remainingFee)
    const fullRefund = this.fullRefundTarget.checked
    const loaderContainer = this.loaderContainerTarget
    const reasonComment = this.reasonCommentTarget

    if (this.isValidRefund(partialRefundAmount, remainingFee, fullRefund)) {
      e.preventDefault()
      this.handleLoaderContainer(fullRefund, loaderContainer, dataSet, partialRefundAmount, reasonComment)
    } else {
      this.handleInvalidValue(paymentField, partialRefundAmount, remainingFee)
    }
  }

  handleInvalidValue(paymentField, partialRefundAmount, remainingFee) {
    if (partialRefundAmount === 0) {
      paymentField.setCustomValidity("Client fee should not be 0")
    } else if (isNaN(partialRefundAmount)) {
      paymentField.setCustomValidity("Please fill in the field")
    } else {
      paymentField.setCustomValidity(`Client fee must be less than or equal to £${remainingFee}`)
    }
  }

  isValidRefund(partialRefundAmount, remainingFee, fullRefund) {
    return (partialRefundAmount > 0 && partialRefundAmount <= remainingFee) || fullRefund
  }

  handleLoaderContainer(fullRefund, loaderContainer, dataSet, partialRefundAmount, reasonComment) {
    const reason = this.reasonTarget
    const reasonCommentLength = parseFloat(reasonComment.value.length)
    if (!reason.value) {
      reason.setCustomValidity("Please select a reason")
      reason.reportValidity()
    } else if (!reasonComment.value) {
      reasonComment.setCustomValidity("Please fill in the field")
      reasonComment.reportValidity()
    } else if (reasonCommentLength <= 500) {
      loaderContainer.classList.add('d-block')
      this.loaderData(fullRefund, loaderContainer, dataSet, partialRefundAmount, reasonComment, reason)
      reasonComment.setCustomValidity("")
    } else {
      reasonComment.setCustomValidity("Characters must be less than 500")
      reasonComment.reportValidity()
    }
  }

  loaderData(fullRefund, loaderContainer, dataSet, partialRefundAmount, reasonComment, reason) {
    const errorMessage = this.errorMessageTarget
    const requestData = this.createRequestData(dataSet, partialRefundAmount, fullRefund, reasonComment, reason)
    
    let fetchResolved = false
  
    fetch('/charges/create_refund', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaValue('csrf-token'),
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then((data) => {
        fetchResolved = true
        this.setData(loaderContainer, errorMessage, data)
      })
      this.getTimeoutPromise(fetchResolved, loaderContainer, errorMessage)
    }

    getTimeoutPromise(fetchResolved, loaderContainer, errorMessage) {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          if (!fetchResolved) {
            reject(new Error('Request timed out'))
          }
        }, 30000)
      })

      timeoutPromise.catch((error) => {
        const data = { message: 'Refund in process. It may take up to 5-7 working days to respond' }
        if (!fetchResolved) {
          this.setData(loaderContainer, errorMessage, data)
        }
      })
    }

  setData(loaderContainer, errorMessage, data) {
    const issueButton = this.issueButtonTarget
    const closeButton = this.closeButtonTarget
    issueButton.remove('d-block')
    loaderContainer.classList.remove('d-block')
    closeButton.classList.add('d-block')
    this.errorMessageContainerTarget.classList.add('d-block')
    errorMessage.innerHTML = data.message
  }

  createRequestData(dataSet, partialRefundAmount, fullRefund, reasonComment, reason) {
    const fullRefundValue = fullRefund ? true : false

    return {
      account_id: dataSet.account,
      instruction_id: dataSet.instructionId,
      partial_refund: partialRefundAmount,
      is_full_refund: fullRefundValue,
      reason: reason.value,
      reason_comment: reasonComment.value
    }
  }
}