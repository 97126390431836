import { Controller } from 'stimulus'
import axios from 'axios'
import PrivateClientController from './private_client_controller'
import { addSelectOption, applyMixins, disableScrollWheel } from '../lib/helper_functions'
import { QuotationSurveyType } from './mixins/quotation_survey_type'

export default class extends PrivateClientController {
  static targets = [
    'descriptionContent', 'privateClient', 'billingContact',
    'billingEmail', 'billingAccount', 'billingInstruction',
    'copyClient', 'clientTypePrivate', 'purchasePrice',
    'estimateValue', 'addSurveyType', 'notifyRecord',
    'clientPaymentDetail', 'clientFee'
  ]
  static values = { accountId: String, vatAmount: String, vatRegistered: Boolean }

  connect(){
    applyMixins(this, [QuotationSurveyType])
    this.getFeeScaleOptions()
    this.clientFeeElements()
    this.privateClientBillingInfo(this.privateClientTarget)
    disableScrollWheel(this.clientFeeTarget)
  }

  getFeeScaleElements(){
    return document.querySelectorAll('.select-fee-scale')
  }

  getFeeScaleOptions(){
    let selectFeeScales = this.getFeeScaleElements()
    selectFeeScales.forEach(selectFeeScale => {
      if (this.privateClientTarget.value == '') {
        this.hideClientSpecificOption(selectFeeScale)
      } else {
        this.showAllOption(selectFeeScale)
      }
    })
  }

  hideClientSpecificOption(selectFeeScale){
    selectFeeScale.options[1].selected = true
    selectFeeScale.options[0].classList.add('d-none')
  }

  showAllOption(selectFeeScale){
    selectFeeScale.options[0].classList.remove('d-none')
  }

  clientFeeElements(){
    let clientFeesSection = document.querySelectorAll('.client-fee-section')
    clientFeesSection.forEach(clientFees => {
      let [clientFee, clientFeeVat] = this.getClientFeeAndClientFeeVat(clientFees)
      if (clientFee.value != '') {
        this.calculateClientFee(clientFee, clientFeeVat)
      }
    })
  }

  calculateClientFee(clientFee, clientFeeVatElement){
    if (!this.vatRegisteredValue) return

    let clientValue = parseFloat(clientFee.value)
    clientFeeVatElement.innerHTML = `£${((clientValue * this.vatAmountValue) + clientValue).toFixed(2)} [inc VAT]`
  }

  toggleApplicantDetail(event){
    if (event.target.value == 'true') {
      this.clearPrivateClientDetail(this.showApplicantFormTarget)
      this.toggleDisplay('d-block', 'd-none')
      this.copyClientTarget.disabled = true
    }
    if (event.target.value == 'false') {
      this.clearPrivateClientDetail(this.showListTarget)
      this.clientTypePrivateTarget.checked = true
      this.toggleDisplay('d-none', 'd-block')
      this.copyClientTarget.disabled = false
    }
    this.clientPaymentDetailTarget.hidden = false
    this.setBillingDetail(false)
    this.updateFeeScaleTarget(event.target.value)
  }

  setBillingDetail(disability, value = '', notify = true){
    if (value != '') {
      notify = value.notify
      value = value.payment_detail
    }
    this.billingContactTarget.value = value.billing_contact || ''
    this.billingEmailTarget.value = value.billing_email || ''
    this.billingAccountTarget.checked = value.on_account || false
    this.billingInstructionTarget.value = value.billing_instructions || ''
    this.notifyRecordTarget.checked = notify || false
    this.billingContactTarget.disabled = disability
    this.billingEmailTarget.disabled = disability
    this.billingAccountTarget.previousElementSibling.disabled = disability
    this.billingAccountTarget.disabled = disability
    this.billingInstructionTarget.disabled = disability
    this.notifyRecordTarget.disabled = disability
  }

  updateFeeScaleTarget(value){
    let selectFeeScales = this.getFeeScaleElements()
    selectFeeScales.forEach(selectFeeScale => {
      if (value == 'true' && this.privateClientTarget.value != '') {
        this.setFeeScaleListOption(selectFeeScale)
      } else {
        this.hideClientSpecificOption(selectFeeScale)
      }
      this.changeSelectFeeScales(selectFeeScale)
    })
  }

  setFeeScaleListOption(selectFeeScale){
    selectFeeScale.options[0].classList.remove('d-none')
    selectFeeScale.options[0].selected = true
  }

  getModifiedSelectFeeScales(e){
    this.changeSelectFeeScales(e.target)
  }

  changeSelectFeeScales(selectFeeScale){
    let value = selectFeeScale.value
    if (value == 'client_specific') {
      this.getClientFeeScales(selectFeeScale)
    } else {
      this.getAllFeeScales(selectFeeScale)
    }
  }

  getClientFeeScales(selectFeeScale){
    let client = this.privateClientTarget.value
    let url = `/accounts/${this.accountIdValue}/private_clients/${client}/client_fee_scales`
    let headers = this.headerAjax()
    let params = { headers }
    axios.get(url, params)
      .then((response)=> {
        this.setFeeScaleListOption(selectFeeScale)
        this.updateFeeScaleDropdown(response.data, selectFeeScale)
    })
  }

  getFeeScaleSections(){
    return document.querySelectorAll('.fee-scale-list-section')
  }

  getFeeScaleParent(selectFeeScale){
    return selectFeeScale.closest('[class="fees-form"]')
  }

  getAllFeeScales(selectFeeScale){
    let url = `/accounts/${this.accountIdValue}/private_clients/list_fee_scales`
    let headers = this.headerAjax()
    let params = { headers }
    let commonParent = this.getFeeScaleParent(selectFeeScale)
    let feeScaleList = commonParent.querySelector('.fee-scale-list')
    feeScaleList.innerHTML = ''
    axios.get(url, params)
      .then((response)=> {
        if (this.privateClientTarget.value == '') {
          this.hideClientSpecificOption(selectFeeScale)
        }
        this.updateFeeScaleDropdown(response.data, selectFeeScale)
    })
  }

  updateFeeScaleDropdown(data, selectFeeScale){
    let commonParent = this.getFeeScaleParent(selectFeeScale)
    let feeScaleList = commonParent.querySelector('.fee-scale-list')
    let feeList = commonParent.querySelector('.fee-list')
    feeScaleList.innerHTML = ''
    this.clearQuotationFeesFields(commonParent)
    this.feeScaleDropdown(data, feeScaleList, feeList)
  }

  clearQuotationFeesFields(commonParent){
    let feesList = commonParent.querySelector('select.fee-list')
    let [clientFee, clientFeeVat] = this.getClientFeeAndClientFeeVat(commonParent)
    feesList.innerHTML = ''
    clientFeeVat.innerHTML = ''
    clientFee.value = ''
  }

  feeScaleDropdown(data, feeScaleList, feeList){
    if (data.length == 0) {
      addSelectOption(feeScaleList, 'Not available')
      addSelectOption(feeList, 'Not available')
    } else {
      addSelectOption(feeScaleList, '-- select --')
      data.forEach((feescale)=> {
        const option = document.createElement('option')
        option.value = feescale.id
        option.innerHTML = feescale.name
        feeScaleList.appendChild(option)
      })
    }
  }

  getPrivateClientDetail(){
    let privateClient = this.privateClientTarget
    if (privateClient.value != '') {
      let url = `/accounts/${this.accountIdValue}/private_clients/${privateClient.value}`
      let headers = this.headerAjax()
      let params = {  params: { headers } }
      axios.get(url, params)
        .then((response)=> {
          this.descriptionContentTarget.innerHTML = response.data
          this.toggleApplicantForm('d-block', 'd-none')
          let selectFeeScales = this.getFeeScaleElements()
          selectFeeScales.forEach(selectFeeScale => {
            this.getClientFeeScales(selectFeeScale)
          })
          this.privateClientBillingInfo(privateClient)
      })
    } else {
      this.descriptionContentTarget.innerHTML = ''
      this.toggleApplicantForm('d-none', 'd-block')
      this.updateFeeScaleTarget(false)
    }
  }

  privateClientBillingInfo(privateClient){
    let cardPayment = privateClient.dataset.cardPaymentActivated
    let client = privateClient.value
    let url = `/accounts/${this.accountIdValue}/private_clients/${client}/payment_detail`
    let headers = this.headerAjax()
    let params = { headers }
    axios.get(url, params)
      .then((response)=> {
        let data = response.data
        let isCardPayment = JSON.parse(cardPayment)
        this.clientPaymentDetailTarget.hidden = (isCardPayment && !data.payment_detail.on_account)
        this.setBillingDetail(true, response.data)
    })
  }

  changeSelectfees(e){
    let feeScaleId = e.target.value
    let url = `/accounts/${this.accountIdValue}/private_clients/list_fees`
    let estimatedPrice = this.estimateValueTarget.value
    let purchasePrice = this.purchasePriceTarget.value
    let commonParent = this.getCommonParent(e)
    let headers = this.headerAjax()
    let params = {  params: { fee_scale_id: feeScaleId, estimated_price: estimatedPrice, purchase_price: purchasePrice }, headers }
    axios.get(url, params)
      .then((response)=> {
        this.updateQuotationFeesDropdown(response.data, estimatedPrice, purchasePrice, commonParent)
    })
  }

  updateQuotationFeesDropdown(responseData, estimatedPrice, purchasePrice, commonParent){
    let feesData = responseData.all_fees
    let selectedFee = responseData.selected_fee
    let feesList = commonParent.querySelector('select.fee-list')
    let [clientFee, clientFeeVat] = this.getClientFeeAndClientFeeVat(commonParent)
    this.clearQuotationFeesFields(commonParent)
    if (feesData.length > 0) {
      addSelectOption(feesList, '-- select --')
      feesData.forEach((value, index)=> {
        let showFee = '£'+ value.lower + '- £'+ value.upper
        let options = feesList.appendChild(document.createElement('option'))
        options.value = value.id
        options.innerHTML = showFee
        let condtion = (estimatedPrice != '' || purchasePrice != '')
        if ( condtion && (selectedFee && value.id == selectedFee.id)) {
          options.selected = true
          clientFee.value = selectedFee.customer
          this.calculateClientFee(clientFee, clientFeeVat)
        }
      })
    } else {
      addSelectOption(feesList, 'Not available')
    }
  }

  getCommonParent(event){
    return event.target.closest('[class="fees-form"]')
  }

  populateQuotationClientFee(e){
    let feeId = e.target.value
    let headers = this.headerAjax()
    let feeParams = {
      params:  { fee_id: feeId },
      headers
    }
    this.calculationQuotation(feeParams, e)
  }

  calculationQuotation(feeParams, event){
    let commonParent = this.getCommonParent(event)
    let [clientFee, clientFeeVat] = this.getClientFeeAndClientFeeVat(commonParent)
    let url = `/accounts/${this.accountIdValue}/private_clients/populate_fee`
    axios.get(url, feeParams)
      .then((response)=> {
        let responseFees = response.data.fees
        if (responseFees == null) {
          clientFee.value = ''
          clientFeeVat.innerHTML = ''
        } else {
          clientFee.value = responseFees.customer
          this.calculateClientFee(clientFee, clientFeeVat)
        }
    })
  }

  calculateQuotationClientFee(e){
    let commonParent = this.getCommonParent(e)
    let [clientFee, clientFeeVat] = this.getClientFeeAndClientFeeVat(commonParent)
    this.calculateClientFee(clientFee, clientFeeVat)
  }

  headerAjax(){
    return { 'Content-Type' : 'application/json', 'Accept' : 'application/json' }
  }

  getClientFeeAndClientFeeVat(commonParent){
    let clientFee = commonParent.querySelector('.client-fee')
    let clientFeeVat = commonParent.querySelector('.client-fee-vat')

    return [clientFee, clientFeeVat]
  }
}