import { Controller } from "stimulus"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
export default class extends Controller {
  static targets = ["address1", "address2", "city", "country", "postcode", "geocoder"]
  connect(){
    this.showSearchBar()
  }

  showSearchBar(){
    let address1 = this.address1Target
    let address2 = this.address2Target
    let city = this.cityTarget
    let country = this.hasCountryTarget ? this.countryTarget : null;
    let postcode = this.postcodeTarget
    let geocoder = new MapboxGeocoder({ // Initialize the geocoder
      accessToken: mapboxgl.accessToken, // Set the access token
      mapboxgl: mapboxgl, // Set the mapbox-gl instance
      countries: 'gb',
      placeholder: 'Find the address...'
    });
    this.setValues(geocoder, address1, address2, city, country, postcode)
  }

  setValues(geocoder, address1, address2, city, country, postcode) {
    this.geocoderTarget.appendChild(geocoder.onAdd());
    geocoder.on('result', function(result) {
      let place = result.result
      let address = place.place_name.split(",")
      address1.value = address[0]
      address2.value = address[1].trim()
      place.context.map((obj) => {
        if(obj.id.includes("district")){
          return city.value = obj.text 
        }
        if(obj.id.includes("postcode")){
          return postcode.value = obj.text 
        }
        if(country && obj.id.includes("country")) {
          return country.value = obj.text;
        }
      });
    });
  }
}
